import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { createCard, updateCard } from "../../store/decksSlice";
import { showNotification } from "../../store/notificationSlice";

interface AddFlashcardProps {
  mode?: "add" | "edit";
}

const AddFlashcard: React.FC<AddFlashcardProps> = ({ mode = "add" }) => {
  const { deckId, cardId } = useParams<{ deckId: string; cardId?: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const editMode = location.state?.mode === "edit" || mode === "edit";

  const deck = useSelector((state: RootState) =>
    state.decks.decks.find((d) => d.id === deckId)
  );
  const card = deck?.cards.find((c) => c.id === cardId);

  const [front, setFront] = useState("");
  const [back, setBack] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editMode && card) {
      setFront(card.english);
      setBack(card.korean);
    }
  }, [editMode, card]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!deckId) {
      dispatch(showNotification({ message: "Deck ID is missing", type: "error" }));
      return;
    }

    setLoading(true);

    try {
      if (editMode && cardId) {
        await dispatch(updateCard({ deckId, cardId, english: front, korean: back })).unwrap();
        dispatch(showNotification({ message: "Card updated successfully!", type: "success" }));
      } else if (!editMode) {
        await dispatch(createCard({ deckId, english: front, korean: back })).unwrap();
        dispatch(showNotification({ message: "Card added successfully!", type: "success" }));
      }
      navigate(`/decks/${deckId}/list`);
    } catch (error) {
      const errorMessage = typeof error === "string" ? error : "An error occurred";
      dispatch(showNotification({ message: errorMessage, type: "error" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full bg-brand-lightest flex flex-col">
      {/* Top Bar */}
      <header className="h-16 bg-white shadow-md flex items-center px-4">
        <h1 className="text-lg font-semibold text-gray-600">
          {editMode ? "Edit Card" : "Add Flashcard"}
        </h1>
      </header>

      {/* Form Section */}
      <main className="flex-1 px-4 py-6">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col bg-white shadow-md rounded-lg p-6 space-y-4 w-full max-w-lg mx-auto"
        >
          {/* Front Input */}
          <div>
            <label
              htmlFor="front"
              className="block text-sm font-medium text-gray-700"
            >
              Front (English)
            </label>
            <textarea
              id="front"
              value={front}
              onChange={(e) => setFront(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-brand focus:border-brand"
              required
            ></textarea>
          </div>

          {/* Back Input */}
          <div>
            <label
              htmlFor="back"
              className="block text-sm font-medium text-gray-700"
            >
              Back (Korean)
            </label>
            <textarea
              id="back"
              value={back}
              onChange={(e) => setBack(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-brand focus:border-brand"
              required
            ></textarea>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 bg-brand-dark text-white font-semibold rounded-md shadow-md hover:bg-brand transition disabled:opacity-50"
            disabled={loading}
          >
            {loading
              ? editMode
                ? "Saving Changes..."
                : "Adding Card..."
              : editMode
              ? "Save Changes"
              : "Add Card"}
          </button>
        </form>
      </main>
    </div>
  );
};

export default AddFlashcard;
