import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { deleteCardFromDeck, fetchDeck } from "../../store/decksSlice";
import { showNotification } from "../../store/notificationSlice";
import { FaTrashAlt, FaEdit, FaPlay, FaPlus } from "react-icons/fa";
import Modal from "react-modal";
import { getFamiliarityDetails} from "./utils/familiarity";

const DeckView: React.FC = () => {
  const { deckId } = useParams<{ deckId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const deck = useSelector((state: RootState) =>
    state.decks.decks.find((d) => d.id === deckId)
  );
  const loading = useSelector((state: RootState) => state.decks.loading);
  const error = useSelector((state: RootState) => state.decks.error);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cardToDelete, setCardToDelete] = useState<string | null>(null);

  useEffect(() => {
    if (deckId && (!deck || deck.cards.length === 0)) {
      // Fetch the full deck with cards if we don't have them yet.
      dispatch(fetchDeck(deckId));
    }
  }, [deck, deckId, dispatch]);

  // If data is loading, show "Loading cards..."
  if (loading) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-brand-lightest">
        <p className="text-lg font-semibold text-gray-600">Loading cards...</p>
      </div>
    );
  }

  // If we have an error and no deck, show the error message
  if (error && !deck) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-brand-lightest">
        <p className="text-lg font-semibold text-red-600">{error}</p>
      </div>
    );
  }

  // If no deck and not loading and no error, it means we have no deck found
  if (!deck && !loading) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-brand-lightest">
        <p className="text-lg font-semibold text-gray-700">Deck not found</p>
      </div>
    );
  }

  // At this point, we have a deck
  const handleOpenModal = (cardId: string) => {
    setIsModalOpen(true);
    setCardToDelete(cardId);
  };

  const handleConfirmDelete = async () => {
    if (cardToDelete && deckId) {
      try {
        await dispatch(deleteCardFromDeck({ deckId, cardId: cardToDelete })).unwrap();
        dispatch(showNotification({ message: "Card deleted successfully!", type: "success" }));
      } catch (err) {
        const errorMessage = typeof err === "string" ? err : "Failed to delete card";
        dispatch(showNotification({ message: errorMessage, type: "error" }));
      }
    }
    setIsModalOpen(false);
    setCardToDelete(null);
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
    setCardToDelete(null);
  };

  return (
    <div className="h-full w-full bg-brand-lightest flex flex-col">
      {/* Top Bar */}
      <header className="h-16 bg-white shadow-md flex items-center px-4">
        <h1 className="text-lg font-semibold text-gray-600">{deck!.name}</h1>
      </header>

      {/* Cards List */}
      <main className="flex-1 px-4 py-6 space-y-4">
        {deck!.cards.length > 0 && (
          <button
            onClick={() => navigate(`/decks/${deck!.id}/carousel`)}
            className="w-full py-2 bg-brand-dark text-white font-semibold rounded-md shadow-sm hover:bg-brand transition flex items-center justify-center"
          >
            <FaPlay className="mr-2" />
            Start
          </button>
        )}

        <button
          onClick={() => navigate(`/decks/${deck!.id}/add`)}
          className="w-full py-2 bg-brand text-white font-semibold rounded-md shadow-sm hover:bg-brand-dark transition flex items-center justify-center"
        >
          <FaPlus className="mr-2" />
          Add New Card
        </button>

        {deck!.cards.map((card) => {
          const { text, className } = getFamiliarityDetails(card.familiarity);
          return (
            <div
              key={card.id}
              className="w-full bg-white rounded-lg shadow-sm p-4 text-gray-600 border border-gray-200 hover:shadow-md transition transform hover:-translate-y-1"
            >
              <div className="mb-2 text-sm font-semibold text-gray-700">
                English: {card.english}
              </div>
              <div className="mb-4 text-sm font-semibold text-gray-700">
                Korean: {card.korean}
              </div>
              <div className="flex items-center justify-between">
                <div className={`px-2 py-1 text-xs font-semibold rounded-md ${className}`}>
                  {text}
                </div>
                <div className="flex space-x-4 text-gray-400">
                  <button
                    onClick={() =>
                      navigate(`/decks/${deck!.id}/edit/${card.id}`, { state: { mode: "edit" } })
                    }
                    className="hover:text-brand-light transition"
                    aria-label="Edit card"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleOpenModal(card.id)}
                    className="hover:text-brand-light transition"
                    aria-label="Delete card"
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </main>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCancelDelete}
        contentLabel="Confirm Delete"
        ariaHideApp={false}
        className="bg-white p-6 rounded shadow-md max-w-sm mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-xl font-semibold mb-4 text-gray-600">Confirm Delete</h2>
        <p className="mb-6 text-gray-600">Are you sure you want to delete this card?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleCancelDelete}
            className="px-4 py-2 bg-gray-300 text-gray-600 rounded hover:bg-gray-400 transition"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirmDelete}
            className="px-4 py-2 bg-brand-dark text-white rounded hover:bg-brand transition"
          >
            Delete
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeckView;
