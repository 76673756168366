import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "../store";
import { clearNotification } from "../store/notificationSlice";

const ToastListener: React.FC = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state: RootState) => state.notification);

  useEffect(() => {
    if (notification.message) {
      if (notification.type === "success") {
        toast.success(notification.message);
      } else if (notification.type === "error") {
        toast.error(notification.message);
      }
      dispatch(clearNotification()); // Clear the notification after showing
    }
  }, [notification, dispatch]);

  return null;
};

export default ToastListener;
