import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { fetchDecks } from "../../store/decksSlice";
import { getFamiliarityDetails} from "./utils/familiarity";

const FlashcardDecks: React.FC = () => {
  const dispatch = useAppDispatch();
  const decks = useSelector((state: RootState) => state.decks.decks);
  const loading = useSelector((state: RootState) => state.decks.loading);
  const error = useSelector((state: RootState) => state.decks.error);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchDecks());
  }, [dispatch]);

  if (loading) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-brand-lightest">
        <p className="text-lg font-semibold text-gray-600">Loading decks...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full w-full flex items-center justify-center bg-brand-lightest">
        <p className="text-lg font-semibold text-red-600">{error}</p>
      </div>
    );
  }

  return (
    <div className="h-full w-full bg-brand-lightest flex flex-col">
      {/* Top Bar */}
      <header className="h-16 bg-white shadow-md flex items-center px-4">
        <h1 className="text-lg font-semibold text-gray-600">My Decks</h1>
      </header>

      {/* Deck List */}
      <main className="flex-1 px-4 py-6 space-y-4">
        {decks.map((deck) => (
          <div
            key={deck.id}
            role="button"
            tabIndex={0}
            onClick={() => navigate(`/decks/${deck.id}/list`)}
            onKeyDown={(e) => e.key === "Enter" && navigate(`/decks/${deck.id}/list`)}
            className="w-full bg-white rounded-lg shadow-md p-4 text-gray-600 hover:shadow-lg hover:bg-brand-lightest transition cursor-pointer"
          >
            <h2 className="text-lg font-semibold">{deck.name}</h2>
            {deck.averageFamiliarity !== undefined ? (
              <p className="text-sm text-gray-500 flex items-center space-x-2 pt-2">
                <span
                  className={`px-2 py-1 text-xs font-semibold rounded-md ${getFamiliarityDetails(deck.averageFamiliarity).className}`}
                >
                  {getFamiliarityDetails(deck.averageFamiliarity).text}
                </span>
              </p>
            ) : (
              <p className="text-sm text-gray-500">Average Familiarity: Not available</p>
            )}
          </div>
        ))}

        {/* Create Deck Button */}
        <button
          onClick={() => navigate("/decks/new")}
          className="w-full py-2 bg-brand-dark text-white font-semibold rounded-md shadow-md hover:bg-brand transition"
        >
          Create New Deck
        </button>
      </main>
    </div>
  );
};

export default FlashcardDecks;
