import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "."; // Import AppDispatch type

interface AuthState {
  token: string | null;
}

const initialState: AuthState = {
  token: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    signOut: (state) => {
      state.token = null;
    },
  },
});

export const { signIn, signOut } = authSlice.actions;

// Thunk to sign in and initialize dummy data
export const signInAndInitialize = (token: string) => (dispatch: AppDispatch) => {
  dispatch(signIn(token)); // Set token
};

export const selectIsAuthenticated = (state: { auth: AuthState }) =>
  !!state.auth.token;

export default authSlice.reducer;
