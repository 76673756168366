import { useEffect } from "react";
import { useAppDispatch } from "../store/hooks";
import { syncRatings } from "../store/decksSlice";

const RatingsSync: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(syncRatings());
    }, 10000); // 10s

    return () => {
      clearInterval(interval);
    };
  }, [dispatch]);

  return null;
};

export default RatingsSync;

// Add this line to make the file a module
export {};
