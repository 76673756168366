import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { createDeck } from "../../store/decksSlice";
import { showNotification } from "../../store/notificationSlice";

const AddFlashcardDeck: React.FC = () => {
  const [deckName, setDeckName] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!deckName) {
      dispatch(showNotification({ message: "Deck name is required", type: "error" }));
      return;
    }
    setLoading(true);

    try {
      await dispatch(createDeck(deckName)).unwrap();
      dispatch(showNotification({ message: "Deck created successfully!", type: "success" }));
      navigate("/decks");
    } catch (error) {
      const errorMessage = typeof error === "string" ? error : "An error occurred";
      dispatch(showNotification({ message: errorMessage, type: "error" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full w-full bg-brand-lightest flex flex-col">
      <header className="h-16 bg-white shadow-md flex items-center px-4">
        <h1 className="text-lg font-semibold text-gray-800">Create New Deck</h1>
      </header>
      <main className="flex-1 px-4 py-6">
        <form
          onSubmit={handleSubmit}
          className="flex flex-col bg-white shadow-md rounded-lg p-6 space-y-4 w-full max-w-lg mx-auto"
        >
          <div>
            <label
              htmlFor="deckName"
              className="block text-sm font-medium text-gray-700"
            >
              Deck Name
            </label>
            <input
              id="deckName"
              type="text"
              value={deckName}
              onChange={(e) => setDeckName(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-brand focus:border-brand"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 bg-brand-dark text-white font-semibold rounded-md shadow-md hover:bg-brand transition disabled:opacity-50"
            disabled={loading}
          >
            {loading ? "Creating Deck..." : "Create Deck"}
          </button>
        </form>
      </main>
    </div>
  );
};

export default AddFlashcardDeck;
