import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { rateCard, syncRatings } from "../../store/decksSlice";
import { FaVolumeUp, FaSpinner, FaArrowLeft } from "react-icons/fa";
import apiClient from "../../api/apiClient";
import { AxiosResponse } from "axios";
import { selectNextCard } from "./utils/cardSelection";
import LRUCache from "../../utils/LRUCache";

interface Card {
  id: string;
  english: string;
  korean: string;
  familiarity: number;
  ratings: number[];
}

const FlashcardCarousel: React.FC = () => {
  const { deckId } = useParams<{ deckId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const deck = useSelector((state: RootState) =>
    state.decks.decks.find((d) => d.id === deckId)
  );

  const [currentCard, setCurrentCard] = useState<Card | null>(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showEnglish, setShowEnglish] = useState(true);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);

  // Component-scoped LRU cache for audio
  const MAX_CACHE_SIZE = 50;
  const audioCache = useRef(new LRUCache<string, Blob>(MAX_CACHE_SIZE));

  const cards = deck?.cards || [];
  const lastRatedCards = deck?.lastRatedCards || [];

  const pickNextCard = () => {
    const nextCard = selectNextCard(cards, lastRatedCards);
    // console.log('Selected Card', nextCard?.id);
    setCurrentCard(nextCard || null);
    setShowAnswer(false);
    if (nextCard) {
      setShowEnglish(Math.random() < 0.5);
    }
  };

  useEffect(() => {
    if (cards.length > 0) {
      pickNextCard();
    }
  }, [cards]);

  const handleNext = (rating: number) => {
    if (deckId && currentCard) {
      dispatch(rateCard({ deckId, cardId: currentCard.id, rating }));
    }
    pickNextCard();
  };

  const handlePlayAudio = async () => {
    if (isPlayingAudio || !currentCard) return;

    setIsPlayingAudio(true);

    const playAudio = async (retry: boolean = false) => {
      try {
        // Check if audio blob is cached
        const cachedAudioBlob = audioCache.current.get(currentCard.id);

        let audioBlob: Blob;

        if (cachedAudioBlob && !retry) {
          audioBlob = cachedAudioBlob; // Use cached audio blob
        } else {
          // Fetch audio from the API
          const response: AxiosResponse<ArrayBuffer> = await apiClient.get(
            `/decks/${deckId}/cards/${currentCard.id}/audio`,
            { responseType: "arraybuffer" }
          );

          audioBlob = new Blob([response.data], { type: "audio/mpeg" });

          // Add audio blob to the cache
          audioCache.current.set(currentCard.id, audioBlob);
        }

        // Create an audio element and play the blob
        if (audioElement) {
          audioElement.pause();
          URL.revokeObjectURL(audioElement.src);
        }

        const audioUrl = URL.createObjectURL(audioBlob);
        const newAudioElement = new Audio(audioUrl);
        setAudioElement(newAudioElement);

        newAudioElement.play();
        newAudioElement.onended = () => setIsPlayingAudio(false);
        newAudioElement.onerror = () => {
          // Handle audio play error: flush cache and retry
          console.error(`Audio play failed for card ${currentCard.id}. Retrying...`);
          audioCache.current.delete(currentCard.id); // Remove problematic cache entry
          playAudio(true); // Retry fetching and playing audio
        };
      } catch (error) {
        console.error("Error fetching audio:", error);
        setIsPlayingAudio(false);
      }
    };

    playAudio();
  };



  useEffect(() => {
    return () => {
      if (audioElement) {
        audioElement.pause();
        if (audioElement.src.startsWith("blob:")) {
          URL.revokeObjectURL(audioElement.src);
        }
      }
    };
  }, [audioElement]);

  useEffect(() => {
    return () => {
      dispatch(syncRatings());
    };
  }, [dispatch]);

  if (!deck) {
    return (
      <div className="p-4 bg-brand-lightest h-screen flex flex-col items-center justify-center">
        <p className="text-lg font-semibold text-gray-800 mb-4">
          Deck not found. Please select a valid deck.
        </p>
        <button
          onClick={() => navigate("/decks")}
          className="px-4 py-2 bg-brand-dark text-white rounded hover:bg-brand transition"
        >
          Go Back
        </button>
      </div>
    );
  }

  if (!currentCard) {
    return (
      <div className="p-4 bg-brand-lightest h-screen flex flex-col items-center justify-center">
        <p className="text-lg font-semibold text-gray-800 mb-4">
          No cards available to study.
        </p>
        <button
          onClick={() => navigate("/decks")}
          className="px-4 py-2 bg-brand-dark text-white rounded hover:bg-brand transition"
        >
          Go Back
        </button>
      </div>
    );
  }

  const isKoreanBottom = showAnswer && showEnglish;
  const audioBtnClasses = isPlayingAudio
    ? "bg-gray-300"
    : isKoreanBottom
    ? "bg-white text-brand-dark"
    : "bg-brand-dark text-white";

  const renderAudioButton = () => (
    <button
      onClick={handlePlayAudio}
      disabled={isPlayingAudio}
      className={`absolute bottom-4 right-4 p-2 rounded-full ${audioBtnClasses} shadow-lg hover:bg-brand transition`}
      aria-label="Play pronunciation"
    >
      {isPlayingAudio ? <FaSpinner className="animate-spin" /> : <FaVolumeUp />}
    </button>
  );

  const renderTextWithNewlines = (text: string) => {
    const lines = text.split("\n");
    return lines.map((line, index) => (
      <span key={index} className={index === 0 ? "text-3xl" : "text-lg"}>
        {line}
        {index < lines.length - 1 && <br />}
      </span>
    ));
  };

  return (
    <div className="h-[calc(100vh_-_250px)] w-full bg-brand-lightest flex flex-col items-center">
      {/* Top Navbar for Carousel */}
      <div className="w-full h-12 flex items-center justify-start px-4 bg-white shadow-md mb-1">
        <button
          onClick={() => navigate(`/decks/${deck.id}/list`)}
          className="flex items-center text-gray-600 hover:text-brand-dark transition"
          aria-label="Back to Deck"
        >
          <FaArrowLeft className="mr-2" />
          Back to {deck.name}
        </button>
      </div>

      <div className="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-5/6 mt-0">
        {/* Top Section */}
        <div className="relative bg-white p-6 flex items-center justify-center h-1/2">
          <div className="text-3xl font-bold text-brand-dark text-center">
            {renderTextWithNewlines(showEnglish ? currentCard.english : currentCard.korean)}
          </div>
          {!showEnglish && renderAudioButton()}
        </div>

        {/* Bottom Section */}
        {!showAnswer ? (
          <div className="bg-brand-light p-6 h-1/2 flex items-center justify-center">
            <button
              onClick={() => setShowAnswer(true)}
              className="px-6 py-3 bg-brand-dark text-white font-semibold rounded-md hover:bg-brand transition"
            >
              👁️ Show Answer
            </button>
          </div>
        ) : (
          <div className="flex-1 flex flex-col bg-brand-light p-6">
            <div className="flex-1 flex flex-col items-center justify-center relative">
              <div className="text-2xl font-semibold text-brand-lighttext text-center mb-4">
                {renderTextWithNewlines(showEnglish ? currentCard.korean : currentCard.english)}
              </div>
              {showEnglish && renderAudioButton()}
            </div>

            {/* Rating Buttons */}
            <div className="flex">
              {["😡", "😕", "😐", "🙂", "😄"].map((emoji, index) => (
                <button
                  key={index}
                  onClick={() => handleNext(index + 1)}
                  className="flex-1 py-4 text-lg font-medium bg-white text-brand-dark hover:bg-brand hover:text-white transition border-t border-brand-light"
                >
                  {emoji}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FlashcardCarousel;
