  export function getFamiliarityDetails(score: number) {
    if (score >= 4)
      return {
        text: "Well Known",
        className: "bg-green-200 text-green-800",
      };
    if (score >= 2.5)
      return {
        text: "Moderate",
        className: "bg-orange-200 text-orange-800",
    };
    return {
      text: "Needs Practice",
      className: "bg-red-200 text-red-800",
    };
  };