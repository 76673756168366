import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../store/authSlice";

const UnauthenticatedRoute: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return !isAuthenticated ? children : <Navigate to="/decks" />;
};

export default UnauthenticatedRoute;
