import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import authReducer, { signOut } from "./authSlice";
import notificationReducer from "./notificationSlice";
import decksReducer, { resetDecks } from "./decksSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  notification: notificationReducer,
  decks: decksReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "notification", "decks"], // Persist these slices
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true, //process.env.NODE_ENV !== "production", // Enable Redux DevTools
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export const resetStore = () => (dispatch: AppDispatch) => {
  dispatch(signOut()); // Reset auth slice
  dispatch({ type: "RESET_NOTIFICATION" }); // Reset notification slice
  dispatch(resetDecks()); // Reset decks slice
};

// Export types
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

export default store;
