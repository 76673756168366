// ui/src/store/decksSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../api/apiClient";
import axios from "axios";

const MAX_RATINGS = 5;
const MAX_LAST_RATED_CARDS = 10;

interface Card {
  id: string;
  english: string;
  korean: string;
  familiarity: number;
  ratings: number[];
}

interface BackendDeck {
  _id: string;
  name: string;
  cards?: Card[];
  average_familiarity: number;
  lastRatedCards?: string[];
}

interface Deck {
  id: string;
  name: string;
  cards: Card[];
  averageFamiliarity?: number;
  lastRatedCards: string[];
}

interface PendingRating {
  deckId: string;
  cardId: string;
  rating: number;
}

interface DecksState {
  decks: Deck[];
  loading: boolean;
  error: string | null;
  pendingRatings: PendingRating[]; // New field for pending ratings
}

const initialState: DecksState = {
  decks: [],
  loading: false,
  error: null,
  pendingRatings: [], // Initialize pendingRatings
};

export const fetchDecks = createAsyncThunk<
  Deck[], // Return type of the fulfilled action
  void,   // Argument type
  { rejectValue: string } // Type for rejectWithValue
>(
  "decks/fetchDecks",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get("/decks");
      const decks = response.data.map((deck: BackendDeck): Deck => ({
        id: deck._id,
        name: deck.name,
        averageFamiliarity: deck.average_familiarity, // Include new property
        cards: [], // No cards returned here
        lastRatedCards: deck.lastRatedCards || [],
      }));
      return decks;
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Fetch a single deck with its cards
export const fetchDeck = createAsyncThunk<
  Deck,
  string,
  { rejectValue: string }
>(
  "decks/fetchDeck",
  async (deckId, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/decks/${deckId}`);
      const deck = response.data as BackendDeck;
      return {
        id: deck._id,
        name: deck.name,
        cards: deck.cards || [],
        lastRatedCards: deck.lastRatedCards || [],
      };
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk to sync pending ratings with the backend
export const syncRatings = createAsyncThunk<
  void, // No return value needed
  void,
  { state: { decks: DecksState }; rejectValue: string }
>(
  "decks/syncRatings",
  async (_, { getState, rejectWithValue, dispatch }) => {
    const state = getState().decks;
    const { pendingRatings } = state;

    if (pendingRatings.length === 0) {
      return;
    }

    try {
      await apiClient.post("/decks/ratings", { ratings: pendingRatings });
      console.log('Updated ratings!');
      // On success, dispatch an action to clear pendingRatings
      dispatch(clearPendingRatings());
    } catch (error) {
      // Handle error (optional)
      let errorMessage = "Failed to sync ratings";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk to create a new deck
export const createDeck = createAsyncThunk<
  Deck, // Return type
  string, // Argument type (deck name)
  { rejectValue: string }
>(
  "decks/createDeck",
  async (deckName, { rejectWithValue }) => {
    try {
      const response = await apiClient.post<BackendDeck>("/decks", { name: deckName });
      const deck = response.data;
      const mappedDeck: Deck = {
        id: deck._id,
        name: deck.name,
        cards: deck.cards || [],
        lastRatedCards: deck.lastRatedCards || [],
      };
      return mappedDeck;
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk to create a new card
export const createCard = createAsyncThunk<
  Card,
  { deckId: string; english: string; korean: string },
  { rejectValue: string }
>(
  "decks/createCard",
  async ({ deckId, english, korean }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post<Card>(`/decks/${deckId}/cards`, {
        english,
        korean,
      });
      return response.data;
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

// Async thunk to update an existing card
export const updateCard = createAsyncThunk<
  Card,
  { deckId: string; cardId: string; english: string; korean: string },
  { rejectValue: string }
>(
  "decks/updateCard",
  async ({ deckId, cardId, english, korean }, { dispatch, rejectWithValue }) => {
    try {
      const response = await apiClient.put<Card>(
        `/decks/${deckId}/cards/${cardId}`,
        { english, korean }
      );

      return response.data;
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);


// Async thunk to delete a card
export const deleteCardFromDeck = createAsyncThunk<
  { deckId: string; cardId: string }, // Return type
  { deckId: string; cardId: string }, // Argument type
  { rejectValue: string }
>(
  "decks/deleteCard",
  async ({ deckId, cardId }, { rejectWithValue }) => {
    try {
      await apiClient.delete(`/decks/${deckId}/cards/${cardId}`);
      return { deckId, cardId };
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      return rejectWithValue(errorMessage);
    }
  }
);

const decksSlice = createSlice({
  name: "decks",
  initialState,
  reducers: {
    rateCard: (
      state,
      action: PayloadAction<{ deckId: string; cardId: string; rating: number }>
    ) => {
      const { deckId, cardId, rating } = action.payload;

      const deck = state.decks.find((d) => d.id === deckId);
      if (!deck) {
        console.error(`Deck with ID ${deckId} not found in state.`);
        console.error("Current decks state:", state.decks);
        return;
      }

      const card = deck.cards.find((c) => c.id === cardId);
      if (!card) {
        console.error(`Card with ID ${cardId} not found in deck ${deckId}.`);
        console.error("Current cards in deck:", deck.cards);
        return;
      }

      if (!Array.isArray(card.ratings)) {
        console.error(`Ratings property is missing or invalid on card ${cardId}.`);
        return;
      }

      // Add the rating to the card's ratings array
      card.ratings.push(rating);
      if (card.ratings.length > MAX_RATINGS) {
        card.ratings.shift(); // Remove the oldest rating if the limit is exceeded
      }

      // Update the familiarity score
      card.familiarity =
        card.ratings.reduce((sum, rate) => sum + rate, 0) / card.ratings.length;
      console.log(`New familiarity for card ${cardId}: ${card.familiarity}`);

      // Add to the deck's lastRatedCards
      deck.lastRatedCards.push(cardId);
      if (deck.lastRatedCards.length > MAX_LAST_RATED_CARDS) {
        deck.lastRatedCards.shift();
      }

      // Add to pendingRatings
      state.pendingRatings.push({ deckId, cardId, rating });
    },
    deleteCard: (
      state,
      action: PayloadAction<{ deckId: string; cardId: string }>
    ) => {
      const { deckId, cardId } = action.payload;
      const deck = state.decks.find((d) => d.id === deckId);
      if (deck) {
        deck.cards = deck.cards.filter((card) => card.id !== cardId);
        deck.lastRatedCards = deck.lastRatedCards.filter((id) => id !== cardId);
      }
    },
    editCard: (
      state,
      action: PayloadAction<{
        deckId: string;
        cardId: string;
        english: string;
        korean: string;
        familiarity: number;
      }>
    ) => {
      const { deckId, cardId, english, korean, familiarity } = action.payload;
      const deck = state.decks.find((d) => d.id === deckId);
      if (deck) {
        const card = deck.cards.find((c) => c.id === cardId);
        if (card) {
          card.english = english;
          card.korean = korean;
          card.familiarity = familiarity;
        }
      }
    },
    clearPendingRatings: (state) => {
      state.pendingRatings = [];
    },
    resetDecks: () => initialState, // Resets the state to the initial state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDecks.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDecks.fulfilled, (state, action: PayloadAction<Deck[]>) => {
        state.loading = false;
        state.decks = action.payload;
      })
      .addCase(fetchDecks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch decks";
      })
      // Handle syncRatings rejected case (optional)
      .addCase(syncRatings.rejected, (state, action) => {
        // You can handle errors here or show notifications
        console.error(action.payload);
      })
      // Handle createDeck actions
      .addCase(createDeck.fulfilled, (state, action: PayloadAction<Deck>) => {
        state.decks.push(action.payload);
        state.loading = false;
      })
      .addCase(createDeck.rejected, (state, action) => {
        state.error = action.payload || "Failed to create deck";
        state.loading = false;
      })
      .addCase(createCard.fulfilled, (state, action) => {
        const { deckId } = action.meta.arg;
        const deck = state.decks.find((d) => d.id === deckId);
        if (deck) {
          deck.cards.push(action.payload);
        }
        state.loading = false;
      })
      .addCase(createCard.rejected, (state, action) => {
        state.error = action.payload || "Failed to create card";
        state.loading = false;
      })
      .addCase(updateCard.fulfilled, (state, action) => {
        const { deckId, cardId } = action.meta.arg;
        const deck = state.decks.find((d) => d.id === deckId);
        if (deck) {
          const cardIndex = deck.cards.findIndex((c) => c.id === cardId);
          if (cardIndex >= 0) {
            deck.cards[cardIndex] = action.payload;
          }
        }
        state.loading = false;
      })
      .addCase(updateCard.rejected, (state, action) => {
        state.error = action.payload || "Failed to update card";
        state.loading = false;
      })
      .addCase(deleteCardFromDeck.fulfilled, (state, action) => {
        const { deckId, cardId } = action.payload;
        const deck = state.decks.find((d) => d.id === deckId);
        if (deck) {
          deck.cards = deck.cards.filter((card) => card.id !== cardId);
          deck.lastRatedCards = deck.lastRatedCards.filter((id) => id !== cardId);
        }
        state.loading = false;
      })
      .addCase(deleteCardFromDeck.rejected, (state, action) => {
        state.error = action.payload || "Failed to delete card";
        state.loading = false;
      })
      .addCase(fetchDeck.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDeck.fulfilled, (state, action: PayloadAction<Deck>) => {
        state.loading = false;
        const existingDeckIndex = state.decks.findIndex(d => d.id === action.payload.id);
        if (existingDeckIndex !== -1) {
          state.decks[existingDeckIndex] = action.payload;
        } else {
          state.decks.push(action.payload);
        }
      })
      .addCase(fetchDeck.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch deck";
      });;
  },
});

export const {
  rateCard,
  deleteCard,
  editCard,
  clearPendingRatings,
  resetDecks,
} = decksSlice.actions;

export default decksSlice.reducer;
