import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import SignIn from "./features/auth/SignIn";
import SignUp from "./features/auth/SignUp";
import FlashcardDecks from "./features/flashcards/FlashcardDecks";
import AddFlashcardDeck from "./features/flashcards/AddFlashcardDeck";
import AddFlashcard from "./features/flashcards/AddFlashcard";
import FlashcardCarousel from "./features/flashcards/FlashcardCarousel";
import DeckView from "./features/flashcards/DeckView";
import ProtectedRoute from "./components/ProtectedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      {/* Unauthenticated Routes */}
      <Route
        path="/signin"
        element={
          <UnauthenticatedRoute>
            <SignIn />
          </UnauthenticatedRoute>
        }
      />
      <Route
        path="/signup"
        element={
          <UnauthenticatedRoute>
            <SignUp />
          </UnauthenticatedRoute>
        }
      />

      {/* Protected Routes */}
      <Route
        path="/decks"
        element={
          <ProtectedRoute>
            <FlashcardDecks />
          </ProtectedRoute>
        }
      />
      <Route
        path="/decks/new"
        element={
          <ProtectedRoute>
            <AddFlashcardDeck />
          </ProtectedRoute>
        }
      />
      <Route
        path="/decks/:deckId/list"
        element={
          <ProtectedRoute>
            <DeckView />
          </ProtectedRoute>
        }
      />
      <Route
        path="/decks/:deckId/add"
        element={
          <ProtectedRoute>
            <AddFlashcard mode="add" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/decks/:deckId/edit/:cardId"
        element={
          <ProtectedRoute>
            <AddFlashcard mode="edit" />
          </ProtectedRoute>
        }
      />
      <Route
        path="/decks/:deckId/carousel"
        element={
          <ProtectedRoute>
            <FlashcardCarousel />
          </ProtectedRoute>
        }
      />

      {/* Catch-All Route */}
      <Route path="*" element={<Navigate to="/signin" />} />
    </Routes>
  );
};

export default AppRoutes;
