import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer, toast, ToastContentProps } from 'react-toastify';
import ToastListener from "./components/ToastListener";
import AppRoutes from "./Routes";
import AppShell from "./components/AppShell"; // Import your AppShell component
import RatingsSync from "./components/RatingsSync";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="bottom-right" // Move toaster to bottom right
        autoClose={2000} // Duration in milliseconds
        hideProgressBar={false} // Optional: Hide progress bar
        newestOnTop={true} // Show newest toasts on top
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastListener />
      <AppShell>
        {/* Routes are rendered inside the AppShell layout */}
        <AppRoutes />
        <RatingsSync />
      </AppShell>
    </BrowserRouter>
  );
};

export default App;
