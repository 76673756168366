import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { resetStore } from "../store";
import { selectIsAuthenticated } from "../store/authSlice";

import Logo from '../assets/white-bunny.png';

const AppShell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const handleSignOut = () => {
    dispatch(resetStore());
    navigate("/signin");
  };

  return (
    <div className="min-h-screen flex flex-col bg-brand-lightest">
      {/* Top Navbar */}
      <header className="sticky top-0 z-50 w-full h-16 flex items-center justify-between px-6 shadow-sm bg-brand-dark">
        <Link to="/" className="flex items-center space-x-2">
          <img width="50px" src={Logo} alt="Logo" />
          <span className="text-white text-xl font-bold">tokki</span>
        </Link>
        <div className="flex items-center space-x-4">
          {isAuthenticated ? (
            <>
              <Link
                to="/decks"
                className="text-white hover:text-brand-lightest transition"
              >
                My Decks
              </Link>
              <button
                onClick={handleSignOut}
                className="py-1 px-3 bg-brand text-white font-medium rounded-md hover:bg-brand-light transition"
              >
                Sign Out
              </button>
            </>
          ) : (
            <>
              <Link
                to="/signin"
                className="text-white hover:text-brand-lightest transition"
              >
                Sign In
              </Link>
              <Link
                to="/signup"
                className="py-1 px-3 bg-brand text-white font-medium rounded-md hover:bg-brand-light transition"
              >
                Sign Up
              </Link>
            </>
          )}
        </div>
      </header>

      <main className="flex-1 p-2">{children}</main>
    </div>
  );
};

export default AppShell;