import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { signInAndInitialize } from "../../store/authSlice";
import { showNotification } from "../../store/notificationSlice";
import { FaUser, FaLock } from "react-icons/fa";
import apiClient from "../../api/apiClient";
import axios from "axios";

const SignIn: React.FC = () => {
  const [identifier, setIdentifier] = useState(""); // For email or username
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await apiClient.post("/auth/login", {
        identifier, // Could be email or username
        password,
      });
      const { token } = response.data;
      dispatch(signInAndInitialize(token));
      dispatch(showNotification({ message: "Welcome back to tokki!", type: "success" }));
      navigate("/decks");
    } catch (error) {
      let errorMessage = "An unknown error occurred";
      if (axios.isAxiosError(error)) {
        errorMessage = error.response?.data?.error || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }
      dispatch(showNotification({ message: errorMessage, type: "error" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto bg-white shadow-md rounded-lg p-6 mt-1">
      <h1 className="text-2xl font-semibold text-gray-600 mb-4">Sign In</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="identifier"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Email or Username
          </label>
          <div className="relative">
            <FaUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              id="identifier"
              type="text"
              placeholder="Enter your email or username"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
              className="w-full pl-10 p-2 border border-gray-300 rounded-md focus:ring-brand focus:border-brand"
              required
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Password
          </label>
          <div className="relative">
            <FaLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              id="password"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 p-2 border border-gray-300 rounded-md focus:ring-brand focus:border-brand"
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-2 bg-brand text-white font-semibold rounded-md hover:bg-brand-dark transition disabled:opacity-50"
          disabled={loading}
        >
          {loading ? "Signing In..." : "Sign In"}
        </button>
      </form>
      <p className="text-sm text-center text-gray-500 mt-4">
        Don’t have an account?{" "}
        <a href="/signup" className="text-brand hover:underline">
          Sign Up
        </a>
      </p>
    </div>
  );
};

export default SignIn;
