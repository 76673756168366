interface Card {
  id: string;
  english: string;
  korean: string;
  familiarity: number;
  ratings: number[];
}

const DEBUG = false;

/**
 * Selects the next card from the given list of cards based on familiarity, recency, and some randomness.
 * 
 * Changes from the original version:
 * - 20% chance to select a "familiar" (more familiar) card instead of a less familiar one.
 * - Instead of always returning the single least familiar card, we find all cards whose familiarity is
 *   within E of the least familiar card and return a random one from that set.
 * - We still avoid recently rated cards if possible.
 * 
 * @param cards - The list of cards in the deck.
 * @param lastRatedCards - The array of recently rated card IDs, representing a history.
 * @returns The selected card, or null if no cards are available.
 */
export function selectNextCard(
  cards: Card[],
  lastRatedCards: string[]
): Card | null {
  if (!cards || cards.length === 0) {
    return null;
  }

  const E = 0.5; // Familiarity threshold, adjust as needed
  const FAMILIAR_CHANCE = 0.2; // 20% chance to pick a more familiar card

  const recentSet = new Set(lastRatedCards);
  if (DEBUG) {
    console.log('recentSet', recentSet);
  }

  // Sort cards by familiarity (ascending)
  const sortedByFamiliarity = [...cards].sort((a, b) => a.familiarity - b.familiarity);
  // Determine the least familiar subset:
  const minF = sortedByFamiliarity[0].familiarity;
  let lowFamiliarityCandidates = sortedByFamiliarity.filter(
    (card) => card.familiarity <= minF + E
  );
  if (DEBUG) {
    console.log('lowFamiliarityCandidates', lowFamiliarityCandidates);
  }

  const halfIndex = Math.floor(sortedByFamiliarity.length / 2);

  if (lowFamiliarityCandidates.length === 1 && recentSet.has(lowFamiliarityCandidates[0].id)) {
    lowFamiliarityCandidates = sortedByFamiliarity.slice(0, halfIndex);
  }

  // Determine a "more familiar" subset:
  // For example, let's take the top half of the deck by familiarity as "familiar".
  const moreFamiliarCandidates = sortedByFamiliarity.slice(halfIndex);
  if (DEBUG) {
    console.log('moreFamiliarCandidates', moreFamiliarCandidates);
  }

  // Decide which set to pick from:
  // With 20% chance, pick from the more familiar set; otherwise, pick from the low familiarity set.
  const pickMoreFamiliar = Math.random() < FAMILIAR_CHANCE;
  if (DEBUG) {
    console.log('pickMoreFamiliar', pickMoreFamiliar);
  }
  const candidatePool = pickMoreFamiliar ? moreFamiliarCandidates : lowFamiliarityCandidates;
  if (DEBUG) {
    console.log('candidatePool', candidatePool);
  }

  // First, try to filter out recently rated cards
  let notRecentlyRated = candidatePool.filter(card => !recentSet.has(card.id));
  if (notRecentlyRated.length === 0) {
    notRecentlyRated = candidatePool.filter(card => !recentSet.has(card.id));
  }
  if (DEBUG) {
    console.log('notRecentlyRated', notRecentlyRated);
  }

  // If we have notRecentlyRated candidates, pick from them; otherwise pick from the full candidate pool
  const finalPool = notRecentlyRated.length > 0 ? notRecentlyRated : candidatePool;
  if (DEBUG) {
    console.log('finalPool', finalPool);
  }

  if (finalPool.length === 0) {
    // If we got here and no candidates are available, return null
    return null;
  }

  // Return a random card from the final pool
  const randomIndex = Math.floor(Math.random() * finalPool.length);
  return finalPool[randomIndex];
}
